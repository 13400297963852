<template>
  <div class="customize-main">
    <table class="customize-form" border="0" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="customize-title1" colspan="7">
            中石化南京工程中东公司政府系统明细<br />SINOPEC NANJING ENGINEERING
            MIDDLE EAST CO., LTDGovernment system details
          </td>
        </tr>
        <tr>
          <td class="text-center">
            序号 <br />
            SN
          </td>
          <td class="text-center">
            名称 <br />
            NAME
          </td>
          <td class="text-center">
            有效期 <br />
            Validity Date
          </td>
          <td class="text-center">
            隶属部门 <br />
            Affiliated departments
          </td>
          <td class="text-center">
            用 途 <br />
            suitable for business
          </td>
          <td class="text-center">
            注册名义<br />
            user name
          </td>
          <td class="text-center">备注<br />remark</td>
        </tr>
        <tr v-for="(item, index) in CostAllocationDetails" :key="index">
          <td class="text-center">
            {{ index + 1 }}
          </td>
          <td class="text-center">
            <el-input v-model="item.Name" placeholder="名称"></el-input>
          </td>
          <td class="text-center">
            <el-date-picker
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              v-model="item.ValidityDate"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </td>
          <td class="text-center">
            <el-input
              v-model="item.AffiliatedDept"
              placeholder="隶属部门"
            ></el-input>
          </td>
          <td class="text-center">
            <el-input
              v-model="item.SuitableforBusiness"
              placeholder="用途"
            ></el-input>
          </td>
          <td class="text-center">
            <el-input v-model="item.UserName" placeholder="注册名义"></el-input>
          </td>
          <td class="text-center">
            <el-input v-model="item.Remark" placeholder="备注"></el-input>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { addGovSystem, updateGovSystem } from "@/api/table";
export default {
  data() {
    return {
      CostAllocationDetails: [],
      costAllocationItem: {
        ID: null,
        Name: null,
        ValidityDate: null,
        AffiliatedDept: null,
        SuitableforBusiness: null,
        UserName: null,
        Remark: null,
        Url: null,
      },
    };
  },

  methods: {
    addCostAllocation(type) {
      const data = [];
      this.CostAllocationDetails.forEach((item) => {
        if (item.Name) {
          data.push(JSON.parse(JSON.stringify(item)));
        }
      });

      if (type === 1) {
        addGovSystem({ list: data }).then((res) => {
          if (res.status === 200) {
            this.$message.success("新建成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      } else {
        updateGovSystem({ list: data }).then((res) => {
          if (res.status === 200) {
            this.$message.success("编辑成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      }
    },
    geteditData(params) {
      console.log("params", params);
      if (params.ValidityDate) {
        params.ValidityDate = params.ValidityDate.slice(0, 10);
      }
      this.CostAllocationDetails[0] = JSON.parse(JSON.stringify(params));
      this.CostAllocationDetails = this.CostAllocationDetails.concat();
      console.log("111");
    },
    clearData() {
      this.CostAllocationDetails = [];
      for (let i = 0; i < 10; i++) {
        const costAllocationItem = JSON.parse(
          JSON.stringify(this.costAllocationItem)
        );
        this.CostAllocationDetails.push(costAllocationItem);
      }
    },
  },
  created() {
    for (let i = 0; i < 10; i++) {
      const costAllocationItem = JSON.parse(
        JSON.stringify(this.costAllocationItem)
      );
      this.CostAllocationDetails.push(costAllocationItem);
    }
  },
};
</script>
<style lang="scss" scoped>
.customize-main {
  width: 100%;
  color: #000;
  .teding {
    max-width: 100%;
  }
  .customize-title1 {
    font-size: 24px;
    text-align: center;
    color: #000;
    font-weight: bolder;
    padding: 5px;
  }
  .customize-herder {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 12px;
  }
  .customize-footer {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 24px;
    .text-box {
      text-align: center;
      div {
        margin-bottom: 8px;
      }
    }
  }
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    margin-top: 12px;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
    }
    .text-center {
      text-align: center;
      font-size: 13px;
      padding: 5px;
      .el-select {
        max-width: 100%;
      }
      .el-input {
        max-width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
      .el-cascader {
        width: 100%;
      }
    }
    .text-title {
      text-align: center;
      font-size: 13px;
      .title {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #e0e0e0;
      }
      .img-box {
        padding: 10px;
        min-height: 100px;
      }
      .img-box >>> img {
        width: 100%;
      }
    }
    .text-right {
      text-align: right;
      font-size: 13px;
      padding: 5px;
    }
    .text-left {
      text-align: left;
      font-size: 10px;
      padding: 5px;
    }
  }
  .customize-form2 {
    border-top: none;
    margin-top: 0;
  }
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.signature {
  max-width: 100px;
}
.felx-box {
  display: flex;
  align-items: center;
}
.img-box {
  max-width: 100px;
  margin-left: 10px;
}
.img-box >>> img {
  width: 100%;
}
</style>